import React from 'react';

const HealthCareListItem = ({ prevention, img}) => {
    return ( 
        <li className="health-care-list--item">
            <img src={img} alt=""/>
            <div className="prevention">
                {prevention}
            </div>
        </li>
     );
}
 
export default HealthCareListItem;