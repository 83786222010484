import React, { useEffect, useState } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import SecondaryHeader from '../../components/Header/SecondaryHeader';
import CovidService from '../../services/CovidService';
import RegularList from '../../components/List/RegularList';

const SymptomPage = () => {
  const [symptom, setSymptom] = useState([]);

  useEffect(() => {
    getSymptoms();
  }, []);

  const getSymptoms = async () => {
    const response = await CovidService.getSymptoms();
    setSymptom(response.data);
    console.log(response.data);
  };

  return (
    <IonPage>
      <SecondaryHeader title="Sintomas" />
      <IonContent>
        <RegularList list={symptom} />
      </IonContent>
    </IonPage>
  );
};

export default SymptomPage;
