import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { medkit, home, helpCircle, globe } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './styles/base/base.scss';

//pages
import HomePage from './pages/HomePage/HomePageLatam';
import AboutPage from './pages/AboutPage/AboutPage';
import HealthPage from './pages/HealthPage/HealthPage';
import TakeCarePage from './pages/HealthPage/TakeCarePage';
import WorldPage from './pages/WorldPage/WorldPage';
import SymptomPage from './pages/HealthPage/SymptomPage';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/homepage" component={HomePage} exact={true} />
          <Route path="/healthpage" component={HealthPage} exact={true} />
          <Route path="/aboutpage" component={AboutPage} />
          <Route path="/worldpage" component={WorldPage} />
          <Route path="/takecarepage" component={TakeCarePage} />
          <Route path="/symptom" component={SymptomPage} />
          <Route
            path="/"
            render={() => <Redirect to="/homepage" />}
            exact={true}
          />
        </IonRouterOutlet>
        <IonTabBar slot="bottom" color="primary">
          <IonTabButton tab="homepage" href="/homepage">
            <IonIcon icon={home} />
          </IonTabButton>
          <IonTabButton tab="worldpage" href="/worldpage">
            <IonIcon icon={globe} />
          </IonTabButton>
          <IonTabButton tab="health" href="/healthpage">
            <IonIcon icon={medkit} />
          </IonTabButton>
          <IonTabButton tab="aboutpage" href="/aboutpage">
            <IonIcon icon={helpCircle} />
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default App;
