import React from 'react';
import HealthCareListItem from './HealthCareListItem';
import './health-care-list.scss';

const HealthCareList = ({ list }) => {
    return ( 
        <ul className="health-care-list">
            {list.map((item, i) => <HealthCareListItem key={i} img={item.img} prevention={item.prevention} />)}
        </ul>
     );
}
 
export default HealthCareList;