import React from 'react';

const CountryListItem = ({
  country,
  confirmed,
  death,
  recovered,
  cases,
  flag,
}) => {
  return (
    <li>
      <div className="country">
        <img src={flag} alt={country} />
        {country}
      </div>
      <div className="status">
        <span className="confirmed">
          <i className="fas fa-user-injured fa-lg"></i>{' '}
          {confirmed < 10 ? `0${confirmed}` : confirmed}
        </span>
        <span className="recovered">
          <i className="fas fa-star-of-life fa-lg"></i>{' '}
          {recovered < 10 ? `0${recovered}` : recovered}
        </span>
        <span className="death">
          <i className="fas fa-skull-crossbones fa-lg"></i>{' '}
          {death < 10 ? `0${death}` : death}
        </span>
      </div>
      <div className="total-cases">Casos totales: {cases} personas</div>
    </li>
  );
};

export default CountryListItem;
