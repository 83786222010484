import React from 'react';
import { IonTitle, IonToolbar } from '@ionic/react';


const Header = ({ title }) => {
    return (
        <IonToolbar color="primary" className="text-center">
            <IonTitle>{title}</IonTitle>
        </IonToolbar>
    );
}

export default Header;