import React from 'react';
import {
  IonPage,
  IonContent,
  IonList,
  IonLabel,
  IonItem,
  IonListHeader,
  IonIcon,
} from '@ionic/react';
import Header from '../../components/Header/Header';
import { heart, information } from 'ionicons/icons';
import './health-page.scss';

const menu = [
  {
    name: 'Cuidados',
    link: '/takecarepage',
    icon: heart,
  },
  {
    name: 'Sintomas',
    link: '/symptom',
    icon: information,
  },
];

const HealthPage = () => {
  return (
    <IonPage className="health-page">
      <Header title="COVID-19 LATAM" />
      <IonContent>
        <IonList>
          <div className="co-container">
            <IonListHeader className="title">A tener en Cuenta</IonListHeader>
          </div>
          {menu.map((item, i) => {
            return (
              <IonItem
                color="light"
                key={i}
                button
                routerLink={item.link ? item.link : '/healthpage'}
              >
                <IonIcon icon={item.icon} slot="start" />
                <IonLabel>{item.name}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default HealthPage;
