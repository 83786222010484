import React, { useState, useEffect } from 'react';
import { IonPage, IonContent, IonText, IonLoading } from '@ionic/react';
import Header from '../../components/Header/Header';
import CountryList from '../../components/List/CountryList';
import CovidService from '../../services/CovidService';
import './WorldPage.scss';

const WorldPage = () => {
  const [world, setWorld] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    getWorldData();
  }, []);

  const getWorldData = async () => {
    setShowLoading(true);
    try {
      const response = await CovidService.getWorld();
      setWorld(response.data);
      setShowLoading(false);
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  return (
    <IonPage>
      <Header title="COVID-19 LATAM" />
      <IonContent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Cargando Datos...'}
        />
        {!showLoading && (
          <div>
            <div className="co-container">
              <IonText color="dark">
                <h1>Lista Mundial</h1>
                <p>* Datos oficiales de la OMS, nombre de paises en ingles</p>
              </IonText>
            </div>
            <CountryList list={world} searchBar />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default WorldPage;
