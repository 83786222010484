import React from 'react';
import { IonPage, IonContent, IonText } from '@ionic/react';
import Header from '../../components/Header/Header';
import './AboutPage.scss';

const AboutPage = () => {
  return (
    <IonPage>
      <Header title="COVID-19 LATAM" />
      <IonContent className="about-page">
        <div className="co-container">
          <IonText color="dark">
            <h1>Sobre Nosotros:</h1>
            <p>
              COVID-19 Latam, es una aplicacion que lo que busca es informar a
              la gente con lo datos reales de la Organizacion Mundial de la
              Salud. Utilizamos la siguiente API:
            </p>
            <a
              href="https://covid.casalox.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Covid API
            </a>
            <p className="copy">Made with ❤ by Sebastian Casal</p>
          </IonText>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AboutPage;
