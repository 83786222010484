import React from 'react';
import { IonList } from '@ionic/react';
import RegularItem from './RegularItem';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import './RegularList.scss';

const RegularList = ({ list }) => {
  return (
    <IonList>
      {list &&
        list.map((item) => (
          <RegularItem
            key={item.id}
            label={item.type}
            icon={item.value ? checkmarkCircleOutline : closeCircleOutline}
            iconColor={item.value}
          />
        ))}
    </IonList>
  );
};

export default RegularList;
