import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonPage,
  IonLoading,
  IonText,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import Header from '../../components/Header/Header';
import CountryList from '../../components/List/CountryList';
import CovidService from '../../services/CovidService';
import './home-page.scss';

const HomePage = () => {
  const [convid, setConvid] = useState([]);
  const [totalCases, setTotalCases] = useState({
    cases: 0,
    deaths: 0,
    recovered: 0,
    updated: 0,
  });
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = async () => {
    setShowLoading(true);

    try {
      const responseLatam = await CovidService.getLatam();
      setConvid(responseLatam.data);
      const responseTotal = await CovidService.getTotalCases();
      setTotalCases(responseTotal.data[0]);
      setShowLoading(false);
    } catch (error) {
      console.log(error);
      setShowLoading(false);
    }
  };

  const handleRefresh = (event) => {
    apiCall();
    event.detail.complete();
  };

  return (
    <IonPage className="darkblue home-page">
      <Header title="COVID-19 LATAM" />
      <IonContent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Cargando Datos...'}
        />
        <IonRefresher
          slot="fixed"
          onIonRefresh={handleRefresh}
          className="refresher"
        >
          <IonRefresherContent
            className="refresher"
            pullingIcon="arrow-dropdown"
            refreshingSpinner="circles"
          ></IonRefresherContent>
        </IonRefresher>
        {!showLoading && (
          <div>
            <div className="co-container">
              <IonText color="dark">
                <div>
                  <h1>Datos Mundiales</h1>
                  <ul className="total-cases">
                    <li>
                      <span>Confirmados Totales:</span> {totalCases.cases}
                    </li>
                    <li>
                      <span>Recuperados Totales:</span> {totalCases.recovered}
                    </li>
                    <li>
                      <span>Muertes Totales:</span> {totalCases.deaths}
                    </li>
                  </ul>
                </div>
                <h2>Lista Latino America</h2>
              </IonText>
            </div>
            <CountryList list={convid} />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
