import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://covid.casalox.com/api',
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  getTotalCases() {
    return apiClient.get('/covid-total');
  },
  getLatam() {
    return apiClient.get('/covid-latam');
  },
  getWorld() {
    return apiClient.get('/covid-world');
  },
  getPrevetion() {
    return apiClient.get('/covid-prevention');
  },
  getSymptoms() {
    return apiClient.get('/covid-symptom');
  },
};
