import React from 'react';
import { IonPage, IonContent } from '@ionic/react';
import HealthCareList from '../../components/List/HealthCareList';

//images
import handWash from '../../assets/img/handwash.png';
import cough from '../../assets/img/cough.png';
import dontTouch from '../../assets/img/donttouch.png';
import maintainSpace from '../../assets/img/maintainspace.png';
import houseKeep from '../../assets/img/housekeep.png';
import cleanSpaces from '../../assets/img/cleanspaces.png';
import mate from '../../assets/img/mate.png';
import chinstrap from '../../assets/img/chinstrap.png';
import SecondaryHeader from '../../components/Header/SecondaryHeader';

const prevention = [
  {
    img: handWash,
    prevention:
      'Higiene de manos mediante el lavado con agua y jabón o fricciones con alcohol en gel, en forma frecuente, en particular luego de toser o estornudar. El uso de alcohol en gel puede sustituir el lavado de manos en caso de que estén visiblemente limpias.',
  },
  {
    img: cough,
    prevention:
      'Buena higiene respiratoria, es decir, al toser o estornudar hacerlo cubriendo la boca y las fosas nasales con el pliegue del codo o utilizando pañuelos descartables.',
  },
  {
    img: dontTouch,
    prevention:
      'Evitar tocarse la cara y mucosa de los ojos, la nariz y la boca.',
  },
  {
    img: maintainSpace,
    prevention:
      'Mantener una distancia de 1 a 2 metros de personas sintomáticas, no saludar con la mano, beso o abrazo; en el mismo sentido, evitar concurrir a sitios donde pueda haber aglomeraciones de personas.',
  },
  {
    img: houseKeep,
    prevention:
      'Distanciamiento social voluntario, con aislamiento y cuarentena de las personas sintomáticas.',
  },
  {
    img: cleanSpaces,
    prevention: 'Mantener los ambientes ventilados y con buena higiene.',
  },
  {
    img: mate,
    prevention:
      'No compartir utensilios de uso personal para bebidas y alimentos, como sorvetes, vasos, cubiertos, mate.',
  },
  {
    img: chinstrap,
    prevention:
      'Sólo deben utilizar mascarillas las personas con síntomas respiratorio y aquellos que asistan a personas sintomáticas o con diagnóstico confirmado de la enfermedad.',
  },
];

const TakeCarePage = () => {
  return (
    <IonPage>
      <SecondaryHeader title="Cuidados" />
      <IonContent>
        <HealthCareList list={prevention} />
      </IonContent>
    </IonPage>
  );
};

export default TakeCarePage;
