import React from 'react';
import { IonItem, IonIcon, IonLabel } from '@ionic/react';

const RegularItem = ({ label, icon, iconColor }) => {
  return (
    <IonItem>
      <IonIcon
        className={`list-item-icon ${iconColor ? 'success' : 'danger'}`}
        slot="end"
        icon={icon}
      />
      <IonLabel>{label}</IonLabel>
    </IonItem>
  );
};

export default RegularItem;
