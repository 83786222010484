/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './CountryList.scss';
import CountryListItem from './CountryListItem';
import { IonInput } from '@ionic/react';

const CountryList = ({ list, searchBar }) => {
  const [searchValue, setSearchValue] = useState('');
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setCountries(list);
  }, []);

  useEffect(() => {
    const filteredList = list.filter(
      (item) =>
        item.country.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
    setCountries(filteredList);
  }, [searchValue]);

  return (
    <div className="list-component">
      <div className="co-container">
        {searchBar && (
          <>
            <IonInput
              value={searchValue}
              className="search-bar"
              placeholder="Busca por nombre del pais"
              onIonChange={(e) => setSearchValue(e.target.value)}
            />
          </>
        )}
      </div>
      {searchBar && <hr className="divider" />}
      <ul className="countries-list">
        {countries &&
          countries.map((item) => {
            return (
              <CountryListItem
                key={item.country}
                country={item.country}
                confirmed={item.active}
                death={item.deaths}
                recovered={item.recovered}
                cases={item.cases}
                flag={item.countryInfo.flag}
              />
            );
          })}
      </ul>
    </div>
  );
};

export default CountryList;
